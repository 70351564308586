<template>
  <div class="position-relative">
    <base-breadcrumb :pages="pages"></base-breadcrumb>
    <!-- SECTION - BANNER -->
    <div class="banner">
      <div class="container">
        <div class="d-flex course-container align-items-center">
          <div style="width:660px">
            <div class="thumbnail-container">
              <img :src="courseDetail ? courseDetail.cover_img : ''" alt="" />
            </div>
          </div>
          <div class="d-none d-sm-block align-self-start" style="width: 50%">
            <h4 class="font-weight-bold">
              {{ courseDetail ? courseDetail.title : "" }}
            </h4>
            <p class="color-text">
              {{ courseDetail ? courseDetail.sub_title : "" }}
            </p>
            <div
              class="d-flex align-items-center flex-wrap"
              style="margin: 10px 0"
            >
              <b-form-rating
                :value="reviewAvg"
                inline
                readonly
                class="m-0 pl-0"
                style="color: #0430AD"
                no-border
                size="lg"
              ></b-form-rating>
              <p class="color-primary text-big font-weight-bold m-0">
                {{ reviewAvg.toFixed(1) }}
              </p>
            </div>
            <h4 v-if="coursePrice === 0" class="font-weight-bold">
              Free course
            </h4>
            <h4 v-else class="font-weight-bold">
              ฿ {{ numberComma(coursePrice.toFixed(2)) }}
            </h4>
            <button
              v-if="!owned"
              class="enroll font-weight-bold"
              @click="goToPayment"
            >
              Enroll
            </button>
            <button v-else class="enroll font-weight-bold" @click="goToCourse">
              Go To Course
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-block d-sm-none">
      <h4 class="font-weight-bold mt-3">
        {{ courseDetail ? courseDetail.title : "" }}
      </h4>
      <p class="color-text">
        {{ courseDetail ? courseDetail.sub_title : "" }}
      </p>
      <div class="d-flex align-items-center flex-wrap">
        <b-form-rating
          :value="reviewAvg"
          inline
          readonly
          class="m-0 pl-0"
          style="color: #0430AD"
          no-border
          size="lg"
        ></b-form-rating>
        <p class="color-primary text-big font-weight-bold m-0">
          {{ reviewAvg.toFixed(1) }}
        </p>
      </div>
    </div>
    <div class="nav d-flex justify-content-center">
      <p
        class="text-big color-text"
        @click="scrollToDescription"
        :class="{ active: currentSection === 'description' }"
      >
        รายละเอียด
      </p>
      <p
        class="text-big color-text"
        @click="scrollToReview"
        :class="{ active: currentSection === 'review' }"
      >
        รีวิว
      </p>
      <p
        class="text-big color-text"
        @click="scrollToInstructor"
        :class="{ active: currentSection === 'instructor' }"
      >
        ผู้สอน
      </p>
    </div>
    <!-- SECTION - COURSE DETAIL -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="font-weight-bold mt-2" ref="description">รายละเอียด</h4>
            <span
              class="text-big color-secondary d-block d-md-none"
              @click="shareToFacebook"
            >
              <u>
                แชร์เนื้อหา
              </u>
            </span>
          </div>
          <div v-if="courseDetail">
            <div class="video-container" v-if="courseDetail.intro_video">
              <iframe
                id="stream-player"
                :src="
                  `https://iframe.videodelivery.net/${courseDetail.intro_video}`
                "
                style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            </div>
          </div>
          <p class="text-big font-weight-bold">
            {{ courseDetail ? courseDetail.title : "" }}
          </p>
          <p class="color-text" style="white-space: pre-wrap;">
            {{ courseDetail ? courseDetail.description : "" }}
          </p>
        </div>
        <div class="col-12 col-md-4">
          <div class="d-flex justify-content-between align-items-center">
            <p class="color-primary text-big font-weight-bold my-3">
              เนื้อหาทั้งหมด
            </p>
            <div
              class="d-none d-md-block"
              @click="shareToFacebook"
              style="cursor: pointer;"
            >
              <img class="mr-2" src="../assets/images/share.svg" alt="" />
              <span class="text-big color-secondary"><u>แชร์เนื้อหา</u></span>
            </div>
          </div>
          <div class="content-list">
            <div
              class="item"
              v-for="content in coursePlaylist"
              :key="content.video.video_id"
            >
              <div class="d-flex">
                <img class="mt-1 mr-2" src="../assets/images/play.svg" alt="" />
                <div>
                  <p class="text-big font-weight-bold">
                    {{ content.video.name }}
                  </p>
                  <p class="m-0">
                    ใช้เวลา {{ secToMin(content.video.second) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SECTION - REVIEW -->
    <div class="container my-5">
      <div class="row mb-4">
        <div class="col-12 col-lg-8">
          <h4 class="font-weight-bold" ref="review" style="margin-left: 5px">
            รีวิว
          </h4>
          <div class="d-flex flex-wrap flex-lg-nowrap">
            <div class="mr-5">
              <h1 style="margin-left: 5px">{{ reviewAvg.toFixed(1) }}</h1>
              <b-form-rating
                :value="reviewAvg"
                inline
                readonly
                class="p-0 mt-n3 color-primary"
                no-border
                size="lg"
              ></b-form-rating>
              <p style="margin-left: 5px">Course Rating</p>
            </div>
            <div
              class="rating d-flex flex-column w-100 justify-content-between"
            >
              <div class="d-flex align-items-center w-100">
                <b-form-rating
                  value="5"
                  inline
                  readonly
                  class="px-0 pr-2 color-primary"
                  no-border
                  size="lg"
                ></b-form-rating>
                <b-progress
                  class="w-100 mr-2"
                  height="10px"
                  :value="(countRating[5] / courseReview.length) * 100"
                  :max="100"
                  :precision="2"
                ></b-progress>
                <p class="text-small">
                  {{
                    Math.floor((countRating[5] / courseReview.length) * 100) ||
                      0
                  }}
                  %
                </p>
              </div>
              <div class="d-flex align-items-center w-100">
                <b-form-rating
                  value="4"
                  inline
                  readonly
                  class="px-0 pr-2 color-primary"
                  no-border
                  size="lg"
                ></b-form-rating>
                <b-progress
                  class="w-100 mr-2"
                  height="10px"
                  :value="(countRating[4] / courseReview.length) * 100"
                  :max="100"
                  :precision="2"
                ></b-progress>
                <p class="text-small">
                  {{
                    Math.floor((countRating[4] / courseReview.length) * 100) ||
                      0
                  }}
                  %
                </p>
              </div>
              <div class="d-flex align-items-center w-100">
                <b-form-rating
                  value="3"
                  inline
                  readonly
                  class="px-0 pr-2 color-primary"
                  no-border
                  size="lg"
                ></b-form-rating>
                <b-progress
                  class="w-100 mr-2"
                  height="10px"
                  :value="(countRating[3] / courseReview.length) * 100"
                  :max="100"
                  :precision="2"
                ></b-progress>
                <p class="text-small">
                  {{
                    Math.floor((countRating[3] / courseReview.length) * 100) ||
                      0
                  }}
                  %
                </p>
              </div>
              <div class="d-flex align-items-center w-100">
                <b-form-rating
                  value="2"
                  inline
                  readonly
                  class="px-0 pr-2 color-primary"
                  no-border
                  size="lg"
                ></b-form-rating>
                <b-progress
                  class="w-100 mr-2"
                  height="10px"
                  :value="(countRating[2] / courseReview.length) * 100"
                  :max="100"
                  :precision="2"
                ></b-progress>
                <p class="text-small">
                  {{
                    Math.floor((countRating[2] / courseReview.length) * 100) ||
                      0
                  }}
                  %
                </p>
              </div>
              <div class="d-flex align-items-center w-100">
                <b-form-rating
                  value="1"
                  inline
                  readonly
                  class="px-0 pr-2 color-primary"
                  no-border
                  size="lg"
                ></b-form-rating>
                <b-progress
                  class="w-100 mr-2"
                  height="10px"
                  :value="(countRating[1] / courseReview.length) * 100"
                  :max="100"
                  :precision="2"
                ></b-progress>
                <p class="text-small">
                  {{
                    Math.floor((countRating[1] / courseReview.length) * 100) ||
                      0
                  }}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SECTION - COMMENT -->
      <!-- <div class="col-12 col-lg-8">
        <p class="text-big">ความคิดเห็น (80)</p>
        <div
          class="comment"
          v-for="comment in commentList"
          :key="comment.userId"
        >
          <div class="d-flex align-items-center mb-3">
            <div class="profile-pic mr-2">
              <img class="w-100" src="../assets/images/noprofile.png" alt="">
            </div>
            <p class="text-big font-weight-bold m-0">{{ comment.name }}</p>
          </div>
          <p>
            {{ comment.content }}
          </p>
        </div>
        <p
          class="see-more color-secondary text-big font-weight-bold text-center"
        >
          ดูเพิ่มเติม
        </p>
      </div> -->
    </div>
    <!-- SECTION - Instructor -->
    <div class="container">
      <div class="d-flex instructor align-items-center" ref="instructor">
        <img
          v-if="lecturerDetail && lecturerDetail.img_url"
          class="mr-4"
          :src="lecturerDetail.img_url"
          alt=""
        />
        <img
          v-else
          class="mr-4"
          src="../assets/images/profile/noprofile.png"
          alt=""
        />
        <div>
          <p class="font-weight-bold color-primary text-lg-left text-big m-0">
            {{
              lecturerDetail
                ? `${lecturerDetail.first_name} ${lecturerDetail.last_name}`
                : ""
            }}
          </p>
          <p class="color-text description">
            {{ lecturerDetail ? lecturerDetail.detail : "" }}
          </p>
          <!-- <p class="color-primary font-weight-bold see-more m-0">
            ดูประวัติเพิ่มเติม
          </p> -->
        </div>
      </div>
    </div>
    <hr style="border-top: 3px solid #0430AD;margin: 50px 30%;" />
    <!-- SECTION - Related courses -->
    <div class="container">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h4 class="color-primary font-weight-bold mr-3 mb-0">หลักสูตรแนะนำ</h4>
        <router-link :to="{ name: 'Curriculum' }" class="d-none d-md-block">
          <button class="more-course">
            <p class="text-white text-big font-weight-bold m-0">
              ดูเพิ่มเติม
            </p>
          </button>
        </router-link>
      </div>
      <div class="row" v-if="suggestList.length > 0">
        <div
          v-for="course in suggestList"
          :key="course.id"
          class="col-12 col-md-4 my-2"
        >
          <CourseCard :course="course" type="curriculum" />
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีหลักสูตรแนะนำ</h3>
      </div>
      <router-link :to="{ name: 'Curriculum' }" class="d-block d-md-none">
        <button class="d-block more-course mx-auto">
          <p class="text-white text-big font-weight-bold m-0">
            ดูเพิ่มเติม
          </p>
        </button>
      </router-link>
    </div>
    <div class="enroll-mobile d-block d-sm-none">
      <div class="d-flex justify-content-between align-items-center">
        <h3 v-if="coursePrice === 0" class="font-weight-bold m-0">
          Free course
        </h3>
        <h3 v-else class="font-weight-bold m-0">
          ฿ {{ numberComma(coursePrice.toFixed(2)) }}
        </h3>
        <button v-if="!owned">
          <p class="text-big m-0 font-weight-bold" @click="goToPayment">
            Enroll
          </p>
        </button>
        <button v-else>
          <p class="text-big m-0 font-weight-bold" @click="goToCourse">
            Go To Course
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import BaseBreadcrumb from "../components/BaseBreadcrumb.vue";
import CourseCard from "../components/CourseCard.vue";
import smoothscroll from "smoothscroll-polyfill";
import numberComma from "../util/numberComma";
import { secToMin } from "../util/convertTime";
// kick off the polyfill!
smoothscroll.polyfill();
import axios from "../api/faAPI";
import baseUrl from "../util/backend";
export default {
  components: {
    CourseCard,
    BaseBreadcrumb,
  },
  data() {
    return {
      ownedCourse: [],
      currentSection: "description",
      curriculumDetail: null,
      courseDetail: null,
      lecturerDetail: null,
      courseReview: [],
      reviewAvg: 0,
      countRating: [0, 0, 0, 0, 0, 0],
      coursePrice: 0,
      coursePlaylist: null,
      suggestList: [],
      commentList: [
        {
          userId: 1,
          name: "ธรรมสร เอียดอาดวงศ์",
          content:
            "เนื้อหาการเรียนครอบคลุม มีการยกตัวอย่างเพื่อการเห็นภาพได้ชัดเจน ผู้สอนให้ความใส่ใจในการสอนมาก",
        },
        {
          userId: 2,
          name: "บุณ เมธีมร",
          content:
            "เเรียนจบแล้วครับ คอร์สเรียนดีมาก สามารถนำไปใช้ประโยชน์ได้เลย",
        },
        {
          userId: 3,
          name: "ธรรมนัส คนดีสิริ",
          content: "เนื้อหาการเรียนเข้าใจง่าย เหมาะสำหรับคนทุกประเภท",
        },
      ],
    };
  },
  computed: {
    pages() {
      return [
        {
          name: "หลักสูตรทั้งหมด",
          link: "/curriculum",
        },
        {
          name: this.curriculumDetail ? this.curriculumDetail.curriculum : "",
          link: `/curriculum/${
            this.curriculumDetail ? this.curriculumDetail.curriculum_id : ""
          }`,
        },
        {
          name: this.courseDetail ? this.courseDetail.title : "",
          link: "",
        },
      ];
    },
    owned() {
      return this.ownedCourse.includes(+this.$route.params.courseId);
    },
  },
  methods: {
    numberComma,
    secToMin,
    goToPayment() {
      this.$router.push({
        name: "Payment",
        params: { courseId: this.$route.params.courseId },
      });
    },
    goToCourse() {
      this.$router.push({
        name: "Lesson",
        params: {
          courseId: this.$route.params.courseId,
        },
      });
    },
    setCurrentSection() {
      const re = this.$refs.review;
      const ins = this.$refs.instructor;
      if (
        window.pageYOffset >=
        ins.getBoundingClientRect().top + window.pageYOffset - 150
      )
        this.currentSection = "instructor";
      else if (
        window.pageYOffset >=
        re.getBoundingClientRect().top + window.pageYOffset - 150
      )
        this.currentSection = "review";
      else {
        this.currentSection = "description";
      }
    },
    scrollToDescription() {
      const el = this.$refs.description;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        const y = el.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    scrollToReview() {
      const el = this.$refs.review;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        const y = el.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    scrollToInstructor() {
      const el = this.$refs.instructor;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        const y = el.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    shareToFacebook() {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(location.href),
        "facebook-share-dialog",
        "width=626,height=436"
      );
      return false;
    },
    async loadCourseDetail() {
      try {
        const res = await axios.get(`${baseUrl}/getCourse`, {
          params: { course_id: this.$route.params.courseId },
        });
        console.log(res.data.courseDetail);
        this.curriculumDetail = res.data.courseDetail.curriculum.curriculum;
        this.courseDetail = res.data.courseDetail.course.course;
        this.coursePrice = res.data.courseDetail.price.price || 0;
        this.lecturerDetail = res.data.courseDetail.course.lecturer;
        if (res.data.courseDetail.reviewData) {
          this.courseReview = res.data.courseDetail.reviewData.review;
          this.reviewAvg = res.data.courseDetail.reviewData.averageRating;
          this.countRating = res.data.courseDetail.reviewData.countRating;
        }
        this.coursePlaylist = res.data.courseDetail.playlist;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
    async loadSuggest() {
      try {
        const res = await axios.get(`${baseUrl}/getSuggestCurriculum`);
        this.suggestList = res.data.data;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
    async loadMyCourse() {
      this.$store.dispatch("setLoading", true);
      try {
        if (this.$store.getters.isAuth) {
          let livedCourse = [];
          const res = await axios.get(`${baseUrl}/getUserCourse`, {
            params: { user_id: this.$store.getters.getUser.user_id },
          });
          const timeRes = await axios.get(`${baseUrl}/getTime`);
          console.log(timeRes.data.type.unix);
          const currTime = timeRes.data.type.unix;
          let boughtCourse = res.data.data;
          for (const course of boughtCourse) {
            const dateNow = moment(currTime);
            const expireDate = course.course_expire
              ? moment(course.course_expire).unix()
              : null;
            console.log(expireDate);
            if (expireDate) {
              if (moment.unix(dateNow) < moment.unix(expireDate)) {
                livedCourse.push(course.course.course_id);
              }
            } else {
              livedCourse.push(course.course.course_id);
            }
          }
          this.ownedCourse = livedCourse;
          console.log("lived course", livedCourse);
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.$store.dispatch("setLoading", false);
    },
  },
  created() {
    this.loadCourseDetail();
    this.loadMyCourse();
    this.loadSuggest();
  },
  beforeMount() {
    window.addEventListener("scroll", this.setCurrentSection);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setCurrentSection);
  },
};
</script>

<style scoped>
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.banner {
  background: linear-gradient(90deg, #bfeaf5 0%, #ecf1ff 75.52%);
  /* max-height: 440px; */
}

.banner .container {
  padding: 0;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.banner >>> .form-control {
  background-color: transparent;
}

.banner >>> .b-rating-star:first-of-type {
  padding-left: 0;
}

.enroll {
  color: white;
  background-color: #07c5f8;
  padding: 10px 60px;
  border: none;
}

.nav {
  position: sticky;
  top: 0;
  padding: 30px 0;
  width: 100%;
  background-color: white;
  z-index: 10;
}

.nav p {
  cursor: pointer;
  margin: 0 20px;
  padding: 0 10px;
  font-size: 14px;
}

.nav .active {
  color: #07c5f8 !important;
  border-bottom: 3px solid #07c5f8;
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin-bottom: 20px;
}

.content-list {
  border: 1px solid #d5d5d5;
  height: 300px;
  overflow: auto;
}

/* width */
.content-list::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.content-list::-webkit-scrollbar-track {
  background: #cccccc;
}

/* Handle */
.content-list::-webkit-scrollbar-thumb {
  background: #07c5f8;
  border-radius: 3.5px;
}

/* Handle on hover */
.content-list::-webkit-scrollbar-thumb:hover {
  background: #07c5f8;
}

.content-list .item {
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
}

.content-list img {
  width: 18px;
  height: 18px;
}

.rating >>> .form-control-lg {
  height: fit-content;
}

.rating >>> .progress {
  background-color: #c4c4c4 !important;
}
.rating >>> .progress-bar {
  background-color: #0430ad !important;
}

* >>> .form-control:focus {
  box-shadow: none !important;
}

.rating p {
  margin: 0;
  min-width: 35px;
}

.comment {
  background-color: #f1f1f1;
  padding: 20px;
  margin: 10px 0;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  line-height: 0;
}

.see-more {
  cursor: pointer;
  text-decoration: underline;
  margin: 10px;
}

.instructor img {
  display: block;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.more-course {
  cursor: pointer;
  border: none;
  background-color: #07c5f8;
  padding: 10px 25px;
}

.enroll-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: #f1f1f1;
  padding: 10px 15px;
}

.enroll-mobile button {
  background-color: #07c5f8;
  padding: 10px 35px;
  color: #fff;
  border: none;
}

@media only screen and (min-width: 576px) {
  .course-container {
    padding: 30px 0;
  }
  .thumbnail-container img {
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .nav p {
    font-size: 18px;
  }
}
</style>
