<template>
  <div class="container">
    <div class="d-flex flex-wrap mt-4">
      <p
        v-for="(page, index) in pages"
        :key="index"
        :class="{ 'font-weight-bold': index < pages.length - 1 }"
      >
        <span
          :class="{
            'color-primary': index < pages.length - 1,
            'page-name': index < pages.length - 1,
          }"
          @click="goToPage(page.link)"
          >{{ page.name }}</span
        ><span v-if="index < pages.length - 1">&nbsp;/&nbsp;</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pages"],
  data() {
    return {};
  },
  methods: {
    goToPage(link) {
      if (link) {
        this.$router.push(link);
      }
    },
  },
};
</script>

<style scoped>
.page-name {
  cursor: pointer;
}
</style>
